// std
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

// 3p
import { useNavigate } from 'react-router-dom';

import {
  HangUpButton,
  MicrophoneButton,
  ParticipantView,
  SwitchCameraButton,
  SwitchLayoutButton,
  VideoCameraButton,
  useTimer,
} from 'components/common/chat/video';

import styles from './MeetingView.module.css';
import { Constants, useMediaDevice, useMeeting } from '@videosdk.live/react-sdk';
import { Loader } from 'components/common';
import { useChat } from 'hooks/chat/useChat';
import { useSocket } from 'hooks/useSocket';
import { IChatMessage, IChatThread } from 'interfaces';

export function MeetingView(props: { threadId: string }) {
  const { threadId } = props;

  const navigate = useNavigate();

  const timeElapsed = useTimer();

  const [joined, setJoined] = useState('JOINING');
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [messageText, setMessageText] = useState('');
  const messageInputRef = useRef<HTMLInputElement>(null);
  const [currentThread, setCurrentThread] = useState<IChatThread | null>(null);
  const [isLayoutSwapped, setIsLayoutSwapped] = useState(false);

  const { createMessage, sendMessage } = useChat();
  const { on, off } = useSocket();

  const [state, setState] = useState<
    'CONNECTING' | 'CONNECTED' | 'FAILED' | 'DISCONNECTED' | 'CLOSING' | 'CLOSED'
  >();

  const [permissionError, setPermissionError] = useState<string | null>();

  //Get the method which will be used to join the meeting.
  //We will also get the participants list to display all participants
  const {
    participants,
    end,
    localMicOn,
    muteMic,
    unmuteMic,
    localWebcamOn,
    enableWebcam,
    disableWebcam,
    getWebcams,
    changeWebcam,
  } = useMeeting({
    onMeetingStateChanged({ state }) {
      console.log('STATE', state);
      setState(state);
    },
    //callback for when meeting is joined successfully
    onMeetingJoined: () => {
      setJoined('JOINED');
    },
    onParticipantJoined: (participant) => {
      participant.setQuality('high');
    },
  });

  // Ottieni il thread della chat corrente
  useEffect(() => {
    if (!threadId) return;

    // Qui dovresti ottenere il thread della chat dal backend
    // Per semplicità, creo un thread fittizio con i campi richiesti dall'interfaccia
    const emptyMessage: IChatMessage = {
      uuid: '',
      content: null,
      sendAt: new Date().toISOString(),
      sender: {},
    };

    setCurrentThread({
      uuid: threadId,
      participants: [],
      message: emptyMessage,
      hasNewMessage: false,
      isBlocked: false,
    });
  }, [threadId]);

  // Ascolta i nuovi messaggi
  useEffect(() => {
    const onMessage = (e: { thread: IChatThread; message: IChatMessage }) => {
      const { thread, message } = e;

      // Verifica che il messaggio appartenga al thread corrente
      if (thread.uuid === threadId) {
        setMessages((prevMessages) => {
          // Mantieni solo gli ultimi 3 messaggi se stiamo per aggiungerne uno nuovo (per un totale di 4)
          const updatedMessages =
            prevMessages.length >= 4
              ? [...prevMessages.slice(1), message]
              : [...prevMessages, message];

          return updatedMessages;
        });

        // Rimuovi il messaggio dopo 7 secondi
        setTimeout(() => {
          setMessages((prevMessages) =>
            prevMessages.filter((msg) => msg.uuid !== message.uuid)
          );
        }, 7000);
      }
    };

    on('chat:onmessage', onMessage);

    return () => {
      off('chat:onmessage', onMessage);
    };
  }, [threadId, on, off]);

  const { checkPermissions, requestPermission } = useMediaDevice();

  const handleEnableWebcam = useCallback(async () => {
    const checkAudioVideoPermission = await checkPermissions();
    const cameraPermissionAllowed = checkAudioVideoPermission.get(
      Constants.permission.VIDEO
    );

    if (!cameraPermissionAllowed) {
      try {
        // Richiedi i permessi prima di mostrare il messaggio di errore
        const permissionResult = await requestPermission(Constants.permission.VIDEO);

        // Se i permessi sono stati concessi, abilita la webcam
        if (permissionResult.get(Constants.permission.VIDEO)) {
          setPermissionError(null);
          enableWebcam();
          return;
        }
      } catch (error) {
        console.error('Errore durante la richiesta dei permessi:', error);
      }

      // Mostra il messaggio di errore solo se i permessi non sono stati concessi
      setPermissionError(
        'Per favore concedi i permessi di accesso alla webcam nelle impostazioni del browser.'
      );
      return;
    }

    setPermissionError(null);
    enableWebcam();
  }, [checkPermissions, requestPermission, enableWebcam]);

  const handleDisableWebcam = useCallback(() => {
    disableWebcam();
  }, [disableWebcam]);

  const handleEnableMic = useCallback(async () => {
    const checkAudioVideoPermission = await checkPermissions();

    const microphonePermissionAllowed = checkAudioVideoPermission.get(
      Constants.permission.AUDIO
    );

    if (!microphonePermissionAllowed) {
      try {
        // Richiedi i permessi prima di mostrare il messaggio di errore
        const permissionResult = await requestPermission(Constants.permission.AUDIO);

        // Se i permessi sono stati concessi, abilita il microfono
        if (permissionResult.get(Constants.permission.AUDIO)) {
          setPermissionError(null);
          unmuteMic();
          return;
        }
      } catch (error) {
        console.error('Errore durante la richiesta dei permessi:', error);
      }

      // Mostra il messaggio di errore solo se i permessi non sono stati concessi
      setPermissionError(
        'Per favore concedi i permessi di accesso al microfono nelle impostazioni del browser.'
      );
      return;
    }

    setPermissionError(null);
    unmuteMic();
  }, [checkPermissions, requestPermission, unmuteMic]);

  const [facingMode, setFacingMode] = useState('user');

  const handleSwitchCamera = useCallback(async () => {
    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';

    try {
      const webcams = await getWebcams();

      // If no webcams are available, throw an error
      if (webcams.length === 0) {
        throw new Error('No webcam available');
      }

      // Set default values (first available webcam)
      let selectedDeviceId = webcams[0].deviceId;

      // If we have more than one webcam, try to identify the front and back cameras
      if (webcams.length > 1) {
        // On iOS Safari, labels might not be available or not contain "front"/"back"
        // So we use an alternative approach

        // If we are currently in "user" mode, switch to the second available webcam
        // otherwise return to the first
        if (facingMode === 'user') {
          // Try to find a back camera
          const backCamera = webcams.find(
            (device) =>
              device.label.toLowerCase().includes('back') ||
              device.label.toLowerCase().includes('rear') ||
              device.label.toLowerCase().includes('environment')
          );

          if (backCamera) {
            selectedDeviceId = backCamera.deviceId;
          } else {
            // If we don't find a camera labeled as back, take the second available
            // (on many devices, the second is the back)
            selectedDeviceId = webcams[1].deviceId;
          }
        } else {
          // Try to find a front camera
          const frontCamera = webcams.find(
            (device) =>
              device.label.toLowerCase().includes('front') ||
              device.label.toLowerCase().includes('user')
          );

          if (frontCamera) {
            selectedDeviceId = frontCamera.deviceId;
          } else {
            // If we don't find a camera labeled as front, take the first available
            selectedDeviceId = webcams[0].deviceId;
          }
        }
      }

      // Disable the current webcam
      disableWebcam();

      // Switch the webcam
      changeWebcam(selectedDeviceId);

      // Update the facingMode state
      setFacingMode(newFacingMode);
    } catch (ex) {
      console.error('Error during camera switch:', ex);
      setPermissionError(ex instanceof Error ? ex.message : 'Unknown error');
    }
  }, [changeWebcam, facingMode, disableWebcam, getWebcams]);

  const handleSwitchLayout = useCallback(() => {
    setIsLayoutSwapped((prev) => !prev);
  }, []);

  const hangUpCallButton = useCallback(() => {
    // Termina la chiamata
    end();
    // La navigazione verrà gestita dall'effect che monitora lo stato
  }, [end]);

  useEffect(() => {
    if (state === 'CLOSED') {
      navigate(-1);
    }
  }, [state, navigate]);

  const remotes = useMemo(() => {
    return Array.from(participants.values())
      .filter(({ local }) => !local)
      .map(({ id }) => id);
  }, [participants]);

  const local = useMemo(() => {
    return Array.from(participants.values())
      .filter(({ local }) => local)
      .map(({ id }) => id);
  }, [participants]);

  // Gestione dei messaggi
  const handleSendMessage = useCallback(() => {
    if (messageText.trim() === '' || !currentThread) return;

    try {
      // Crea un nuovo messaggio usando l'hook useChat
      const newMessage = createMessage(messageText);

      // Aggiungi il messaggio localmente per visualizzazione immediata
      setMessages((prevMessages) => {
        const updatedMessages =
          prevMessages.length >= 4
            ? [...prevMessages.slice(1), newMessage]
            : [...prevMessages, newMessage];

        return updatedMessages;
      });

      // Rimuovi il messaggio dopo 7 secondi
      setTimeout(() => {
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.uuid !== newMessage.uuid)
        );
      }, 7000);

      // Invia il messaggio tramite socket
      sendMessage(currentThread, newMessage);

      setMessageText('');

      // Focus sull'input dopo l'invio
      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    } catch (error) {
      console.error("Errore nell'invio del messaggio:", error);
    }
  }, [messageText, currentThread, createMessage, sendMessage]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  if (joined === 'JOINING' || state === 'CONNECTING') {
    return (
      <div className={`${styles.positionCenter}`}>
        <Loader />
      </div>
    );
  }

  return (
    <div className="chat-containe">
      {permissionError && (
        <div className={styles.errorOverlay}>
          <button
            onClick={() => setPermissionError(null)}
            className={styles.dismissButton}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-x"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
          <div className={styles.errorMessage}>{permissionError}</div>
        </div>
      )}

      {/* Timer Box */}
      <div className={styles.timerBox}>
        <span className={styles.timer}>{timeElapsed}</span>
      </div>

      {/* Overlay dei messaggi */}
      <div className={`${styles.messagesOverlay} md:mb-4`}>
        {[...messages].reverse().map((message) => (
          <div key={message.uuid} className={styles.messageItem}>
            <span className="font-bold">{message.sender.username} </span>
            <span>{message.content}</span>
          </div>
        ))}
      </div>

      <div className={`${styles.actionBarWrapper}`}>
        <div className={styles.actionBarContainer}>
          <SwitchCameraButton
            disable={!localWebcamOn}
            switchCamera={handleSwitchCamera}
          />

          <VideoCameraButton
            isCameraEnable={localWebcamOn}
            startVideoButton={handleEnableWebcam}
            stopVideoButton={handleDisableWebcam}
          />

          <MicrophoneButton
            isMicrophoneEnable={localMicOn}
            startMicrophoneButton={handleEnableMic}
            stopMicrophoneButton={muteMic}
          />

          <SwitchLayoutButton
            switchLayout={handleSwitchLayout}
            isSwapped={isLayoutSwapped}
            disable={participants.size <= 1}
          />

          {/* Chiusura Chiamata */}
          <HangUpButton hangUpCallButton={hangUpCallButton} />
        </div>
      </div>

      {/* Input per i messaggi */}
      <div className={`${styles.chatInputWrapper} md:mb-4`}>
        <input
          type="text"
          ref={messageInputRef}
          className={styles.chatInput}
          placeholder="Scrivi un messaggio..."
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button
          className={styles.sendButton}
          onClick={handleSendMessage}
          disabled={messageText.trim() === '' || !currentThread}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-send-horizontal"
          >
            <path d="M3.714 3.048a.498.498 0 0 0-.683.627l2.843 7.627a2 2 0 0 1 0 1.396l-2.842 7.627a.498.498 0 0 0 .682.627l18-8.5a.5.5 0 0 0 0-.904z" />
            <path d="M6 12h16" />
          </svg>
        </button>
      </div>

      {/* Visualizzazione video in base allo stato di isLayoutSwapped */}
      {isLayoutSwapped ? (
        <>
          {localWebcamOn && (
            <div className={styles.mainVideo}>
              {local.map((participantId) => (
                <ParticipantView participantId={participantId} key={participantId} />
              ))}
            </div>
          )}

          {participants.size > 1 ? (
            <div className={styles.selfVideo}>
              {remotes.map((participantId) => (
                <ParticipantView participantId={participantId} key={participantId} />
              ))}
            </div>
          ) : null}
        </>
      ) : (
        <>
          <div className={styles.selfVideo}>
            {local.map((participantId) => (
              <ParticipantView participantId={participantId} key={participantId} />
            ))}
          </div>

          {participants.size > 1 ? (
            <div className={styles.mainVideo}>
              {remotes.map((participantId) => (
                <ParticipantView participantId={participantId} key={participantId} />
              ))}
            </div>
          ) : (
            <div className={styles.positionCenter}>
              <h1 style={{ color: '#fff', fontWeight: 'bold' }}>
                In attesa di partecipanti
              </h1>
            </div>
          )}
        </>
      )}
    </div>
  );
}
