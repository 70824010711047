import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

// app

// 3p
import { IChatMessage, IMedia2, IUser } from 'interfaces';
import { useAuth, useModal, useOnScrollTop } from 'hooks';
import { MediaModal } from '../media/MediaModal';
import { formatDateDistance } from 'utils';
import { MessageVideo } from './MessageVideo';
import { MessageAudio } from './MessageAudio';
import { MessagePhoto } from './MessagePhoto';
import { ROLES } from 'config';
import { DeleteIcon, MessageDirection } from './DeleteIcon';

function messageGroup(messages: IChatMessage[]) {
  return messages.reduce((prev, curr) => {
    const { uuid, sender, sendAt } = curr;

    if (!prev.length) {
      const group = {
        uuid: uuid,
        sender: sender,
        messages: [curr],
        sendAt: sendAt,
      };

      return [group];
    }

    const prevGroup = prev[prev.length - 1];
    const { sender: prevSender } = prevGroup;

    // è lo stesso utente, quindi aggiungo il messaggio
    if (prevSender.id === sender.id) {
      prevGroup.messages.push(curr);
    } else {
      // è un altro utente
      const group = {
        uuid: uuid,
        sender: sender,
        messages: [curr],
        sendAt: sendAt,
      };
      return [...prev, group];
    }

    return prev;
  }, [] as IChatMessageGroup[]);
}

interface IChatMessageGroup {
  uuid: string;
  sender: Partial<IUser>;
  messages: IChatMessage[];
  sendAt: string;
  onViewImage?: (media: any) => void;
  onViewVideo?: (video: any) => void;
}

const STYLES = {
  [MessageDirection.OUT]: {
    first:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-t-lg rounded-l-lg bg-pink-500 max-w-xs lg:max-w-md',
    center:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-l-lg bg-pink-500 max-w-xs lg:max-w-md',
    last: 'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-b-lg rounded-l-lg bg-pink-500 max-w-xs lg:max-w-md',
    group: 'flex flex-col items-end justify-center group',
    container: 'flex flex-row justify-end mt-3',
    body: 'messages text-sm text-white grid grid-flow-row gap-2',
  },
  [MessageDirection.IN]: {
    first:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-t-lg rounded-r-lg bg-gray-100 max-w-xs lg:max-w-md',
    center:
      'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-r-lg bg-gray-100 max-w-xs lg:max-w-md',
    last: 'px-6 py-3 whitespace-pre-wrap text-wrap break-words rounded-b-lg rounded-r-lg bg-gray-100 max-w-xs lg:max-w-md',
    group: 'flex flex-col items-start justify-center group',
    container: 'flex flex-row justify-start mt-3',
    body: 'messages text-sm text-gray-700 grid grid-flow-row gap-2',
  },
};

interface IChatMessageGroupProps extends IChatMessageGroup {
  playingId: string | null;
  setPlayingId: React.Dispatch<React.SetStateAction<string | null>>;
  showSenderUsername?: boolean;
  onDeleteMessage?: (messageId: string) => Promise<void>;
}

const ChatMessageGroup = (props: IChatMessageGroupProps) => {
  const {
    sender,
    messages,
    onViewImage,
    onViewVideo,
    playingId,
    setPlayingId,
    showSenderUsername = false,
    onDeleteMessage,
  } = props;

  const { user } = useAuth();
  const [showIcon, setShowIcon] = useState<string | null>(null);

  const direction = useMemo(() => {
    if (user && user.id === sender.id) {
      return MessageDirection.OUT;
    }

    return MessageDirection.IN;
  }, [sender, user]);

  const isAdmin = useMemo(() => {
    return user && user.role && user.role.code === ROLES.ADMIN;
  }, [user]);

  const onImageClick = useCallback(
    (id: string, url: string) => {
      if (!onViewImage) return;

      onViewImage({ id, url, photo: { url } });
    },
    [onViewImage]
  );

  const onVideoClick = useCallback(
    (id: string, url: string) => {
      if (!onViewVideo) return;

      onViewVideo({ id, video: { url } });
    },
    [onViewVideo]
  );

  const handleDeleteMessage = useCallback(
    async (uuid: string) => {
      if (onDeleteMessage) {
        try {
          await onDeleteMessage(uuid);
          // Nascondi l'icona dopo il click solo se l'eliminazione è avvenuta con successo
          setShowIcon(null);
        } catch (error) {
          // In caso di errore, potremmo gestirlo qui
          console.error("Errore durante l'eliminazione del messaggio:", error);
          // Non nascondiamo l'icona in caso di errore per permettere all'utente di riprovare
        }
      }
    },
    [onDeleteMessage]
  );

  const handleToggleIcon = useCallback(
    (uuid: string) => {
      // Se è già visibile, lo nasconde. Altrimenti, lo mostra.
      // Utilizziamo una funzione per aggiornare lo stato per garantire che abbiamo sempre l'ultimo valore
      setShowIcon((prev) => (prev === uuid ? null : uuid));
    },
    [] // Non dipende da setShowIcon perché è una funzione di setState stabile
  );

  // Chiude l'icona quando si clicca fuori dalla nuvoletta su mobile
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Verifica che l'elemento cliccato non sia all'interno di una bolla di messaggio
      const target = event.target as Element;
      if (!target.closest('.message-bubble')) {
        setShowIcon(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Wrapper per il componente DeleteIcon che passa le props corrette
  const DeleteIconWrapper = useCallback(
    ({ uuid, deleteAt }: { uuid: string; deleteAt?: string }) => {
      return (
        <DeleteIcon
          uuid={uuid}
          deleteAt={deleteAt}
          isVisible={showIcon === uuid}
          onDelete={handleDeleteMessage}
          direction={direction}
          isAdmin={isAdmin}
        />
      );
    },
    [showIcon, handleDeleteMessage, direction, isAdmin]
  );

  return (
    <>
      {showSenderUsername ? (
        <div className="font-bold	text-lg text-left mb-2">{sender.username}</div>
      ) : null}
      <div className={STYLES[direction].container}>
        {/* AVATAR MINUSCOLO {direction === MessageDirection.IN && (
        <div className="w-8 h-8 relative flex flex-shrink-0 mr-4">
          <img
            className="shadow-md rounded-full w-full h-full object-cover"
            src="https://randomuser.me/api/portraits/women/33.jpg"
            alt=""
          />
        </div>
      )} */}
        <div className={STYLES[direction].body}>
          {messages.map((message, i) => {
            const { uuid, content, photo, video, audio, sendAt, deleteAt } = message;

            const isFirstMessage = i === 0;
            const isLastMessage = i === messages.length - 1;

            const messageStyle = isFirstMessage
              ? STYLES[direction].first
              : isLastMessage
                ? STYLES[direction].last
                : STYLES[direction].center;

            const { distance, seconds } = formatDateDistance(sendAt);
            const formattedsendAt = seconds > 180 ? distance : 'Poco fa';

            return (
              <div
                key={uuid}
                className={`${STYLES[direction].group} group relative message-bubble`}
                onClick={(e) => {
                  // Verifica che il click non sia avvenuto sull'icona di eliminazione
                  const target = e.target as Element;
                  if (!target.closest('svg')) {
                    handleToggleIcon(uuid);
                  }
                }}
              >
                <p className="mr-1 text-xs text-gray-400">{formattedsendAt}</p>

                {video ? (
                  <div
                    className={`flex items-center ${direction === MessageDirection.IN ? 'flex-row-reverse space-x-reverse' : ''} space-x-2`}
                  >
                    <DeleteIconWrapper uuid={uuid} deleteAt={deleteAt} />
                    <MessageVideo video={video} onVideoClick={onVideoClick} />
                  </div>
                ) : null}

                {photo ? (
                  <div
                    className={`flex items-center ${direction === MessageDirection.IN ? 'flex-row-reverse space-x-reverse' : ''} space-x-2`}
                  >
                    <DeleteIconWrapper uuid={uuid} deleteAt={deleteAt} />
                    <MessagePhoto photo={photo} onImageClick={onImageClick} />
                  </div>
                ) : null}

                {audio ? (
                  <div
                    className={`flex items-center ${direction === MessageDirection.IN ? 'flex-row-reverse space-x-reverse' : ''} space-x-2`}
                  >
                    <DeleteIconWrapper uuid={uuid} deleteAt={deleteAt} />
                    <MessageAudio
                      audio={audio}
                      id={uuid}
                      playingId={playingId}
                      setPlayingId={setPlayingId}
                    />
                  </div>
                ) : null}

                {content && (
                  <div
                    className={`flex items-center ${direction === MessageDirection.IN ? 'flex-row-reverse space-x-reverse' : ''} space-x-2`}
                  >
                    <DeleteIconWrapper uuid={uuid} deleteAt={deleteAt} />
                    <p className={messageStyle}>{content}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

interface IChatAreaContentProps {
  messages: IChatMessage[];
  showSenderUsername?: boolean;
  fetch: () => Promise<void>;
  isFetching: boolean;
  onDeleteMessage?: (messageId: string) => Promise<void>;
}

export const ChatAreaContent = (props: IChatAreaContentProps) => {
  const { messages, fetch, showSenderUsername = false, onDeleteMessage } = props;

  const [playingId, setPlayingId] = useState<string | null>(null);

  const {
    openModal: viewMediaModalOpen,
    closeModal: viewMediaModalClose,
    modalData: viewMediaModal,
    setData: viewMediaModalSetData,
  } = useModal<IMedia2 | any>();

  const handleOnViewImage = (image: any) => {
    viewMediaModalSetData(image);
    viewMediaModalOpen();
  };

  const handleOnViewVideo = (video: any) => {
    viewMediaModalSetData(video);
    viewMediaModalOpen();
  };

  const messagesGroupByUser = useMemo(() => {
    return messageGroup(messages);
  }, [messages]);

  const { chatBoxRef, handleChatBoxScroll } = useOnScrollTop({
    messages,
    onFetchNewMessages: fetch,
  });

  if (!messages) {
    return <>Non ci sono messaggi</>;
  }

  return (
    <>
      <MediaModal {...viewMediaModal} onClose={viewMediaModalClose} />
      <div
        className="flex-1 px-3 overflow-y-auto"
        ref={chatBoxRef}
        onScroll={handleChatBoxScroll}
      >
        {/* {isFetching ? <Loader /> : null} */}
        {messagesGroupByUser.map((group) => (
          <Fragment key={group.uuid}>
            <ChatMessageGroup
              showSenderUsername={showSenderUsername}
              onViewImage={handleOnViewImage}
              onViewVideo={handleOnViewVideo}
              playingId={playingId}
              setPlayingId={setPlayingId}
              onDeleteMessage={onDeleteMessage}
              {...group}
            />
          </Fragment>
        ))}
      </div>
    </>
  );
};
