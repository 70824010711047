import { useCallback, useEffect, useState } from 'react';

// 3p
import moment from 'moment';
/* This example requires Tailwind CSS v2.0+ */
import { PaperClipIcon } from '@heroicons/react/solid';

// app
import { MediaModal } from 'components/common/media/MediaModal';
import { ROLES } from 'config/Roles';
import { IMedia, IMedia2, IModal, IUserTableRow } from 'interfaces';
import { useToast } from 'hooks';

interface IUserBodyProfileProps {
  user: IUserTableRow;
}

export const UserBodyProfile = (props: IUserBodyProfileProps) => {
  const { user } = props;

  const [mediaModal, setMediaModal] = useState<IModal<IMedia>>({
    open: false,
  });

  const [hasFront, setHasFront] = useState<boolean>(false);

  const [hasBack, setHasBack] = useState<boolean>(false);

  const [hasSelfie, setHasSelfie] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;

    const documentFrontImage = user.documentList
      .reverse()
      .find((doc: any) => doc.type.code === 'CREATOR_IDENTITY_FRONT');

    if (documentFrontImage) {
      setHasFront(documentFrontImage);
    }

    const documentBackImage = user.documentList
      .reverse()
      .find((doc: any) => doc.type.code === 'CREATOR_IDENTITY_BACK');

    if (documentBackImage) {
      setHasBack(documentBackImage);
    }

    const documentSelfieImage = user.documentList
      .reverse()
      .some((doc: any) => doc.type.code === 'CREATOR_IDENTITY_SELFIE');

    if (documentSelfieImage) {
      setHasSelfie(documentSelfieImage);
    }
  }, [user]);

  const [document, setDocument] = useState<any>();

  const mediaModalOpen = () => {
    setMediaModal({ open: true });
  };

  const mediaModalClose = () => {
    setMediaModal({ open: false });
  };

  const handleOnClick = (document: any) => {
    const doc: IMedia2 = {
      photo: document.fileStorage,
      id: document.id,
      url: document.fileStorage.url,
    };
    setDocument(doc);
    mediaModalOpen();
  };

  const { toastSuccess } = useToast();

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(user.chatId);
    toastSuccess({ title: 'Codice copiato' });
  }, [toastSuccess, user.chatId]);

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-5">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Dettagli utente</h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">E-mail</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {user.email}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Data di registrazione</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {moment(user.signupAt).format('DD-MM-YYYY')}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Chat con supporto</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                {user.chatId}
                <button
                  onClick={copyToClipboard}
                  className="ml-2 px-2 py-1 bg-pink-500 text-white text-sm rounded"
                >
                  Copia
                </button>
              </dd>
            </div>

            {user.role.code === ROLES.CREATOR && (
              <>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Nome e cognome</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user.profile?.fullname}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Data di nascita</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {moment(user.profile?.birthday).format('DD-MM-YYYY')}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Bio</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user.profile?.biography}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Visibile</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user.profile
                      ? user.profile.isVisible
                        ? 'Visibile'
                        : 'Non Visibile'
                      : ' '}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Approvazione</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {user.role.code === ROLES.CREATOR && user.profile
                      ? user.profile.approveAt
                        ? 'Approvato'
                        : 'Non ancora approvato'
                      : ' '}
                  </dd>
                </div>
                {document ? (
                  <MediaModal
                    data={document}
                    onClose={mediaModalClose}
                    open={mediaModal.open}
                  />
                ) : null}
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Documenti</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {!hasFront && !hasBack && !hasSelfie ? null : (
                      <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                        {hasFront && (
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <PaperClipIcon
                                className="shrink-0 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-2 flex-1 w-0 truncate">
                                Fronte documento
                              </span>
                            </div>
                            <div className="ml-4 shrink-0">
                              <button
                                onClick={() =>
                                  handleOnClick(
                                    user.documentList.find(
                                      (doc: any) =>
                                        doc.type.code === 'CREATOR_IDENTITY_FRONT'
                                    )
                                  )
                                }
                                className="font-medium text-pink-600 hover:text-pink-500"
                              >
                                Apri
                              </button>
                            </div>
                          </li>
                        )}
                        {hasBack && (
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <PaperClipIcon
                                className="shrink-0 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-2 flex-1 w-0 truncate">
                                Retro documento
                              </span>
                            </div>
                            <div className="ml-4 shrink-0">
                              <button
                                onClick={() =>
                                  handleOnClick(
                                    user.documentList.find(
                                      (doc: any) =>
                                        doc.type.code === 'CREATOR_IDENTITY_BACK'
                                    )
                                  )
                                }
                                className="font-medium text-pink-600 hover:text-pink-500"
                              >
                                Apri
                              </button>
                            </div>
                          </li>
                        )}
                        {hasSelfie && (
                          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                            <div className="w-0 flex-1 flex items-center">
                              <PaperClipIcon
                                className="shrink-0 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-2 flex-1 w-0 truncate">
                                Selfie con documento
                              </span>
                            </div>
                            <div className="ml-4 shrink-0">
                              <button
                                onClick={() =>
                                  handleOnClick(
                                    user.documentList.find(
                                      (doc: any) =>
                                        doc.type.code === 'CREATOR_IDENTITY_SELFIE'
                                    )
                                  )
                                }
                                className="font-medium text-pink-600 hover:text-pink-500"
                              >
                                Apri
                              </button>
                            </div>
                          </li>
                        )}
                      </ul>
                    )}
                  </dd>
                </div>
              </>
            )}
          </dl>
        </div>
      </div>
    </>
  );
};
