// 3p
import { useParams } from 'react-router-dom';

// app
import {
  uploadVideo,
  useGetAllThread,
  useGetAllThreadMessage,
  useGetThread,
} from 'api/chat';
import { AlertError, Loader } from 'components/common';
import { ChatAreaContent, ChatAreaFooter, ChatAreaHeader } from 'components/common/chat';
import { useChat, useSocket } from 'hooks';
import { IChatMedia, IEventOnBlock, IEventOnDelete, IEventOnMessage } from 'interfaces';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ENV_CHAT } from 'config';
import APIClient from 'api/ApiClient';

const notifyUploadStatus = async (
  bunnyId: string,
  status: number,
  errorDetail?: string
) => {
  await APIClient.post(ENV_CHAT.NOTIFY_UPLOAD_STATUS, {
    VideoGuid: bunnyId,
    Status: status,
    ErrorDetail: errorDetail,
  });
};

export const ChatDetail = () => {
  const { chatId } = useParams<{ chatId: string }>() as { chatId: string };

  const [isUploading, setIsUploading] = useState<string[]>([]);

  // Handle new messages
  const { on, off } = useSocket();

  const notifyUploadInterrupted = useCallback(async (bunnyId: string, ex: Error) => {
    const { name, message, stack } = ex;
    const error = `[${name}] ${message} \n ${stack}`;
    await notifyUploadStatus(bunnyId, 5, error);
  }, []);

  // Load thread
  const { getThreadQuery, setThreadBlock } = useGetThread(chatId);

  const {
    data: threadQueryResult,
    isLoading: threadQueryIsLoading,
    isError: threadQueryIsError,
    // error: threadQueryError,
  } = getThreadQuery;

  // Load messages
  const { getAllThreadMessageQuery, updateMessage, deleteMessage } =
    useGetAllThreadMessage(chatId);

  const {
    data: messageQueryResult,
    isError: messageQueryIsError,
    // error: messageQueryError,
    // isLoading: messageQueryIsLoading,
    // isFetching,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
  } = getAllThreadMessageQuery;

  const messages = useMemo(() => {
    if (!messageQueryResult) return [];
    return messageQueryResult.pages.flatMap(({ data }) => data);
  }, [messageQueryResult]);

  // ********* SEND MESSAGE
  const {
    createMessage,
    createAttachment,
    sendMessage,
    blockUser,
    deleteMessage: deleteMessageSocket,
  } = useChat();
  const { updateLastMessage } = useGetAllThread();

  const onSendMessage = useCallback(
    async (text: string | null, media?: IChatMedia) => {
      if (!threadQueryResult) {
        throw new Error('chatId not found');
      }

      if (!media && (!text || !text.trim().length)) {
        throw new Error('No content');
      }

      try {
        const content = createMessage(text);
        const attachment = media ? createAttachment(media) : undefined;

        const response = await sendMessage(threadQueryResult, content, attachment);

        const { message, metadata } = response;
        const { video } = message;

        if (video) {
          if (media) {
            // A questo punto posso iniziare l'upload del video...
            const { file } = media;
            const { TUSKey, libraryId, bunnyId, TUSExpiration, fileName } = metadata;

            const newFile = new File([file], fileName, { type: file.type });

            setIsUploading((prev) => [...prev, bunnyId]);

            uploadVideo(
              newFile,
              TUSKey,
              libraryId,
              bunnyId,
              TUSExpiration,
              (v: number) => {
                const n = { ...message, video: { ...video, progress: v } };
                updateMessage(n);
              }
            )
              .then(async () => {
                await notifyUploadStatus(bunnyId, 999);
              })
              .catch(async (ex) => {
                console.error("Errore durante l'upload:", ex);
                await notifyUploadStatus(bunnyId, 5, ex);
              })
              .finally(() => {
                setIsUploading((prev) => prev.filter((v) => v !== bunnyId));
              });
          }
        }

        // Chat & thread message list
        updateMessage(message);
        updateLastMessage(threadQueryResult, message);
      } catch (e) {
        alert(e);
      }
    },
    [
      threadQueryResult,
      createMessage,
      createAttachment,
      sendMessage,
      updateMessage,
      updateLastMessage,
    ]
  );

  const handleDeleteMessage = useCallback(
    async (messageId: string) => {
      if (window.confirm('Sei sicuro di voler eliminare questo messaggio?')) {
        try {
          if (threadQueryResult && threadQueryResult.uuid) {
            // Prima invia la richiesta al server attraverso la socket
            await deleteMessageSocket(threadQueryResult.uuid, messageId);

            // Poi aggiorna l'interfaccia utente rimuovendo il messaggio dalla cache
            deleteMessage(messageId);
          }
        } catch (error) {
          console.error("Errore durante l'eliminazione del messaggio:", error);
          alert('Non è stato possibile eliminare il messaggio');
        }
      }
    },
    [threadQueryResult, deleteMessageSocket, deleteMessage]
  );

  const fetchPreviousMessages = useCallback(async () => {
    if (hasPreviousPage) {
      fetchPreviousPage();
    }
  }, [fetchPreviousPage, hasPreviousPage]);

  useEffect(() => {
    const onMessage = (e: IEventOnMessage) => {
      const { thread, message } = e;
      // Se il messaggio ricevuto non è di questa chat, non lo mostro
      const { uuid: tuuid } = thread;
      if (chatId === tuuid) {
        updateMessage(message);
      }
    };

    const onBlock = (e: IEventOnBlock) => {
      console.log('onBlock', e);
      const { threadId, isBlocked } = e;
      setThreadBlock(threadId, isBlocked);
    };

    const onDeleteMessage = (e: IEventOnDelete) => {
      deleteMessage(e.messageUuid);
    };

    on('chat:onmessage', onMessage);
    on('chat:onblock', onBlock);
    on('chat:ondeletemessage', onDeleteMessage);

    return () => {
      off('chat:onmessage', onMessage);
      off('chat:onblock', onBlock);
      off('chat:ondeletemessage', onDeleteMessage);
    };
  }, [chatId, off, on, setThreadBlock, updateMessage, deleteMessage]);

  useEffect(() => {
    if (!isUploading.length) return;

    function beforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault();

      const ex = new Error('BeforeUnloadEvent');

      isUploading.forEach((bunnyId) => notifyUploadInterrupted(bunnyId, ex));
      return 'Attenzione: Caricamento in corso.';
    }

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [isUploading, notifyUploadInterrupted]);

  if (threadQueryIsError || messageQueryIsError) {
    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AlertError message="Chat non disponibile" />
      </div>
    );
  }

  if (threadQueryIsLoading || !threadQueryResult) {
    return <Loader />;
  }

  return (
    <>
      <ChatAreaHeader thread={threadQueryResult} onBlockUser={blockUser} />
      <ChatAreaContent
        messages={messages}
        isFetching={isFetchingPreviousPage}
        fetch={fetchPreviousMessages}
        onDeleteMessage={handleDeleteMessage}
      />
      <ChatAreaFooter thread={threadQueryResult} onSend={onSendMessage} />
    </>
  );
};
