import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth, useAnalytics } from 'hooks';

import CreatorDashboardView from './Dashboard';
import CreatorProductView from './Products';
import CreatorSettingsView from './Settings';
import CreatorPayoutView from './Payout';
import PurchaseView from './Purchase';

import { ChatList, Meeting, ChatDetail } from './chat';
import { AlbumRouter } from './album';

import { ROLES } from 'config';

import PageNotFound from 'views/web/PageNotFound';
import { ChatLayout, MainLayout } from 'layouts/web';
import { ChatEmpty } from 'components/common/chat';

export const AppRouter = () => {
  const { user } = useAuth();
  const { trackPageView } = useAnalytics();

  const location = useLocation();
  const navigationType = useNavigationType();

  // Tracciamento automatico della visualizzazione pagina ad ogni cambio di route
  useEffect(() => {
    if (navigationType !== 'POP') {
      trackPageView(location.pathname + location.search);
    }
  }, [trackPageView, location, navigationType]);

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={`dashboard`} element={<CreatorDashboardView />} />

        <Route path={`albums/*`} element={<AlbumRouter />} />

        <Route path={`products`} element={<CreatorProductView />} />

        <Route path={`settings/*`} element={<CreatorSettingsView />} />

        <Route path={`payouts`} element={<CreatorPayoutView />} />

        <Route path={`purchases`} element={<PurchaseView />} />

        {user?.role.code === ROLES.CUSTOMER ? (
          <Route index element={<Navigate to={`dashboard`} />} />
        ) : (
          <Route index element={<Navigate to={`payouts`} />} />
        )}

        <Route path="*" element={<PageNotFound />} />
      </Route>

      <Route path={`chat`} element={<ChatLayout />}>
        <Route element={<ChatList />}>
          <Route index element={<ChatEmpty />} />
          <Route path=":chatId" element={<ChatDetail />} />
        </Route>
      </Route>

      <Route path="meeting">
        <Route path={`:meetingId`} element={<Meeting />} />
        <Route index element={<Navigate to="/app/chat" replace={true} />} />
      </Route>
    </Routes>
  );
};
