import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useRef,
} from 'react';
import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';
import * as CONFIG from '../config/Config';

interface QueuedEvent {
  optionsOrName: UaEventOptions | string;
  params?: any;
}

interface IAnalyticsContext {
  trackEvent: (optionsOrName: UaEventOptions | string, params?: any) => void;
  trackPageView: (path?: string) => void;
  consentType: 'full' | 'none' | 'anonymous';
  isInitialized: boolean;
  setAnalyticsConsent: (consent: 'full' | 'anonymous' | 'none') => void;
}

const AnalyticsContext = createContext<IAnalyticsContext | null>(null);

interface IAnalyticsContextProvider {
  children: ReactNode;
}

export function AnalyticsContextProvider({
  children,
}: IAnalyticsContextProvider): JSX.Element {
  const [isInitialized, setIsInitialized] = useState(false);
  const eventQueue = useRef<QueuedEvent[]>([]);

  const [consentType] = useState<'full' | 'none' | 'anonymous'>(() => {
    const consentValue = localStorage.getItem(CONFIG.LOCAL_STORAGE_TRACKING_CONSENT_KEY);

    if (consentValue === 'true') {
      return 'full';
    } else if (consentValue === 'anonymous') {
      return 'anonymous';
    } else {
      return 'none';
    }
  });

  const hasAnalyticsConsent = useMemo(() => {
    return consentType === 'full' || consentType === 'anonymous';
  }, [consentType]);

  const hasFullAnalyticsConsent = useMemo(() => {
    return consentType === 'full';
  }, [consentType]);

  const processEventQueue = useCallback(() => {
    if (!isInitialized || !hasAnalyticsConsent) return;

    // Processa tutti gli eventi in coda
    while (eventQueue.current.length > 0) {
      const event = eventQueue.current.shift();
      if (event) {
        ReactGA.event(event.optionsOrName, event.params);
      }
    }
  }, [isInitialized, hasAnalyticsConsent]);

  const initGA = useCallback((consent: 'full' | 'anonymous' | 'none') => {
    // Configurazione specifica in base al tipo di consenso
    const configByConsent = {
      full: {
        testMode: CONFIG.IS_DEV,
        gaOptions: {
          cookieFlags: 'SameSite=None;Secure',
          cookieUpdate: true,
          cookieDomain: 'auto',
        },
      },
      anonymous: {
        testMode: CONFIG.IS_DEV,
        gaOptions: {
          anonymize_ip: true,
          storage: 'none', // Non memorizzare cookie
          storeGac: false, // Non memorizzare Google Analytics Cookie
          client_storage: 'none',
          cookieFlags: 'SameSite=None;Secure',
        },
      },
      none: null, // Non inizializzare GA
    };

    // Inizializza GA con la configurazione appropriata solo se non è 'none'
    if (configByConsent[consent]) {
      ReactGA.initialize(CONFIG.GA_TRACKING_ID, configByConsent[consent]);

      // Se è anonimo, imposta anche le opzioni di anonimizzazione
      if (consent === 'anonymous') {
        // Imposta l'anonimizzazione dell'IP
        ReactGA.gtag('set', 'anonymize_ip', true);
        // Disabilita il tracciamento degli utenti
        ReactGA.gtag('set', 'allow_ad_personalization_signals', false);
        ReactGA.gtag('set', 'allow_google_signals', false);
      }
    }

    setIsInitialized(true);
  }, []);

  const setAnalyticsConsent = (consent: 'full' | 'anonymous' | 'none'): void => {
    localStorage.setItem(
      CONFIG.LOCAL_STORAGE_TRACKING_CONSENT_KEY,
      consent === 'full' ? 'true' : consent === 'anonymous' ? 'anonymous' : 'false'
    );

    // Se è stato dato almeno un consenso anonimo, inizializza GA
    if (consent === 'full' || consent === 'anonymous') {
      initGA(consent);

      // Registriamo l'evento di consenso
      if (consent === 'full') {
        ReactGA.event('Consent', 'Accepted Analytics Cookies');
      } else {
        ReactGA.event('Consent', 'Accepted Anonymous Analytics');
      }
    } else if (consent === 'none') {
      // Non registriamo eventi se l'utente ha rifiutato completamente
    }
  };

  useEffect(() => {
    if (CONFIG.IS_DEV) return;
    if (consentType === 'none') return;
    if (isInitialized) return;

    initGA(consentType);
  }, [consentType, isInitialized, initGA]);

  // Processa la coda quando GA viene inizializzato
  useEffect(() => {
    processEventQueue();
  }, [isInitialized, processEventQueue]);

  const trackEvent = useCallback(
    (optionsOrName: UaEventOptions | string, params?: any) => {
      if (!hasAnalyticsConsent) return;

      if (!isInitialized) {
        // Se GA non è ancora inizializzato, aggiungi l'evento alla coda
        eventQueue.current.push({ optionsOrName, params });
        return;
      }

      ReactGA.event(optionsOrName, params);
    },
    [isInitialized, hasAnalyticsConsent]
  );

  const trackPageView = useCallback(
    (path?: string) => {
      if (!isInitialized || !hasAnalyticsConsent) return;

      ReactGA.send({
        hitType: 'pageview',
        page: path || window.location.pathname + window.location.search,
      });
    },
    [isInitialized, hasAnalyticsConsent]
  );

  return (
    <AnalyticsContext.Provider
      value={{
        trackEvent,
        trackPageView,
        consentType,
        isInitialized,
        setAnalyticsConsent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error(
      "useAnalytics deve essere usato all'interno di un AnalyticsContextProvider"
    );
  }
  return context;
};
