import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

// 3p
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { BanIcon, CashIcon, VideoCameraIcon } from '@heroicons/react/outline';

// app
import { BadgeTypeEnum, ROLES } from 'config';
import { IChatThread, IChatThreadParticipant, IModal, IPayInTipForm } from 'interfaces';

import { formatDateDistance } from 'utils';
import { useAuth, useToast, useMeeting } from 'hooks';

import { UserImage } from '../image';
import { TipBuyModal } from '../payment';
import { BadgeCheckIcon } from '@heroicons/react/solid';

interface IChatAreaHeaderProps {
  thread: IChatThread;
  onBlockUser?: (username: string) => Promise<void>;
}

export const ChatAreaHeader = (props: IChatAreaHeaderProps) => {
  const { thread, onBlockUser } = props;
  const { participants } = thread;

  const [tipBuyModal, setTipBuyModal] = useState<IModal<IPayInTipForm>>({
    open: false,
  });

  const navigate = useNavigate();

  const { user } = useAuth();

  const { toastError } = useToast();

  const [receiver, setReceiver] = useState<IChatThreadParticipant>();

  const [userId, userRole] = useMemo(() => {
    if (!user) return [];

    const { id, role } = user;

    return [id, role.code];
  }, [user]);

  useEffect(() => {
    if (!participants) return;

    const users = participants.filter((u) => u.id !== userId);

    setReceiver(users[0]);
  }, [participants, userId]);

  const [isUserOnline, setIsUserOnline] = useState<boolean>(false);
  const [lastAccessAt, setLastAccessAt] = useState<string>();

  useEffect(() => {
    if (!receiver) return;

    const { lastActivityAt } = receiver;

    if (lastActivityAt) {
      const { distance, seconds } = formatDateDistance(lastActivityAt);
      setLastAccessAt(distance);
      setIsUserOnline(!(seconds > 300));
    }
  }, [participants, receiver]);

  const tipBuyOpen = useCallback(() => {
    if (userRole === ROLES.CREATOR) {
      alert('Questa opzione può essere utilizzata dai clienti per pagarti una tip');
      return;
    }

    setTipBuyModal({ open: true });
  }, [userRole]);

  const renderParticipants = useMemo(() => {
    const users = participants.filter((u) => u.id !== userId);

    return users.map((u, i) => (
      <Fragment key={u.id}>
        <p className="font-bold">{u.username}</p>
        {u.badge && u.badge.code === BadgeTypeEnum.PAYING_CUSTOMER && (
          <BadgeCheckIcon className="h-4 w-4 ml-0.5" aria-hidden="true" color="#3897f0" />
        )}
        {i === 0 && users.length > 1 && <span className="mr-0.5">,</span>}
      </Fragment>
    ));
  }, [participants, userId]);

  // Images
  const renderImages = useMemo(() => {
    const images = participants
      .filter((u) => u.id !== userId)
      .flatMap(({ image }) => (image ? image.url : []));

    return images.length > 1 ? (
      <>
        <img
          className="shadow-md rounded-full w-10 h-10 object-cover absolute ml-6"
          src={images[0]}
          alt="User2"
        />
        <img
          className="shadow-md rounded-full w-10 h-10 object-cover absolute mt-6"
          src={images[1]}
          alt="User2"
        />
      </>
    ) : (
      <UserImage
        alt={images[0]}
        params={{ class: 'small' }}
        image={{ type: 'image', url: images[0] }}
      />
    );
  }, [participants, userId]);

  const tipBuyClose = () => {
    setTipBuyModal({ open: false });
  };

  const tipError = (error: AxiosError<string, any>) => {
    toastError({
      title: 'Impossibile inviare tip',
      message: error.response?.data,
    });
  };

  const { startCall } = useMeeting();

  const handleOnStartCall = useCallback(() => {
    if (userRole !== ROLES.CREATOR) {
      alert(
        'Solo i creator possono videochiamarti, una volta effettuato il pagamento sarà il creator a mandarti la richiesta di videochiamata'
      );
      return;
    }

    const users = participants.filter((u) => u.id !== userId);
    const username = users[0].username;

    startCall(username);
  }, [participants, startCall, userRole, userId]);

  const { isBlocked } = thread;

  const handleOnBlockUser = useCallback(async () => {
    if (userRole !== ROLES.CREATOR) return;

    const users = participants.filter((u) => u.id !== userId);
    const username = users[0].username;

    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      `Vuoi ${isBlocked ? 'sbloccare' : 'bloccare'} ${username}?`
    );

    if (!confirm) return;

    if (!onBlockUser) return;

    try {
      await onBlockUser(username);
    } catch (error) {
      console.error('Failed to block user:', error);
      alert("Impossibile completare l'operazione. Per favore riprova più tardi.");
    }
  }, [isBlocked, onBlockUser, participants, userId, userRole]);

  return (
    <div className="bg-white pt-4 sm:px-6 border-b-2">
      <div className="flex flex-row flex-none justify-between pb-3 items-center">
        <div className="flex justify-center items-center">
          <div
            className="mx-2 h-6 w-6 relative flex flex-shrink-0 cursor-pointer sm:hidden"
            onClick={() => navigate(-1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </div>
          <div className="w-12 h-12 mr-3 relative flex flex-shrink-0">{renderImages}</div>
          <div className="text-sm">
            <div className="flex">{renderParticipants}</div>
            <p>{isUserOnline ? 'Online' : lastAccessAt}</p>
          </div>
        </div>
        <div className="flex">
          {userRole !== ROLES.ADMIN ? (
            <>
              <button
                type="button"
                className="block rounded-full text-pink-600 hover:text-pink-700 p-2"
                onClick={handleOnStartCall}
              >
                <VideoCameraIcon className="h-8 w-8" aria-hidden="true" />
              </button>

              {userRole === ROLES.CREATOR ? (
                <>
                  <button
                    type="button"
                    className="block rounded-full text-pink-600 hover:text-pink-700 p-2"
                    onClick={handleOnBlockUser}
                    title="Blocca utente"
                  >
                    <BanIcon className="h-8 w-8" aria-hidden="true" />
                  </button>
                </>
              ) : receiver ? (
                <>
                  <TipBuyModal
                    {...tipBuyModal}
                    username={receiver.username}
                    setOpen={setTipBuyModal}
                    onClose={tipBuyClose}
                    onSuccess={tipBuyClose}
                    onError={tipError}
                  />
                  <button
                    type="button"
                    className="block rounded-full text-pink-600 hover:text-pink-700 p-2"
                    onClick={tipBuyOpen}
                    title="Invia una tip"
                  >
                    <CashIcon className="h-8 w-8" aria-hidden="true" />
                  </button>
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      <div className="px-3 py-3">
        <p className="text-xs leading-relaxed	font-medium">
          ⛔️ È ASSOLUTAMENTE VIETATO chiedere o fornire contatti esterni (Telegram,
          Instagram, Whatsapp ecc.)
        </p>
        <p className="text-xs leading-relaxed font-medium">
          ⚠️ VIDEOCHIAMATE: in caso di problemi di visualizzazione/audio consigliamo di
          utilizzare l'ultima versione di CHROME (o SAFARI per iOS)
        </p>
        <p className="text-xs leading-relaxed font-medium">
          <a href="/faq" title="Visita le Faq" className="text-indigo-600 underline">
            Visita la Faq per tutte le info
          </a>
        </p>
      </div>
    </div>
  );
};
