import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { useAnalytics } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const Success = () => {
  // Se non sono loggato vado alla pagina di registrazione
  const [params] = useSearchParams();
  const { trackEvent } = useAnalytics();

  const isPaymentSuccess = useMemo(() => {
    const ans = params.get('ans');
    if (!ans) return true;
    return ans.startsWith('Y');
  }, [params]);

  useEffect(() => {
    if (!isPaymentSuccess) return;

    // If payment is not success, return
    const paymentData = localStorage.getItem('payment_data');
    if (paymentData) {
      const { currency, value } = JSON.parse(paymentData);
      trackEvent('purchase', { currency, value });
      localStorage.removeItem('payment_data');
    }
  }, [isPaymentSuccess, trackEvent]);

  const renderSuccess = () => (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="w-20 text-center m-auto">
            <CheckCircleIcon color="green" />
          </div>
          <h2 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Acquisto eseguito correttamente
          </h2>
          <p className="text-gray-600 my-2 text-center">
            Contatta il creator attraverso i suoi canali social
          </p>
        </div>
      </div>
    </div>
  );

  const renderError = () => (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="w-20 text-center m-auto">
            <ExclamationCircleIcon color="red" />
          </div>
          <h2 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Si è verificato un problema durante l'acquisto
          </h2>
          <p className="text-gray-600 my-2 text-center">
            Contatta l'assistenza per avere supporto
          </p>
        </div>
      </div>
    </div>
  );

  // EPOCH ERROR
  if (!isPaymentSuccess) {
    return renderError();
  }

  // VEROTEL

  // COINGATE

  return renderSuccess();
};
