import { useCallback, useMemo } from 'react';
import styles from './VideoCall.module.css';

interface ISwitchCameraButtonProps {
  switchCamera: () => void;
  disable?: boolean;
}

export function SwitchCameraButton(props: ISwitchCameraButtonProps) {
  const { switchCamera, disable = false } = props;

  const btnStyle = useMemo(
    () => (disable ? styles.btnDisabled : styles.btnCamera),
    [disable]
  );

  const handleOnClick = useCallback(() => {
    if (disable) return;
    switchCamera();
  }, [disable, switchCamera]);

  return (
    <button
      onClick={handleOnClick}
      className={`${btnStyle} transform active:scale-75 transition-transform w-10 h-10 sm:w-12 sm:h-12 flex items-center justify-center`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="lucide lucide-switch-camera w-8 h-8"
      >
        <path d="M11 19H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h5" />
        <path d="M13 5h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-5" />
        <circle cx="12" cy="12" r="3" />
        <path d="m18 22-3-3 3-3" />
        <path d="m6 2 3 3-3 3" />
      </svg>
    </button>
  );
}
