import { memo } from 'react';
import { TrashIcon, XCircleIcon } from '@heroicons/react/outline';
import { Tooltip } from '../tooltip';

// Enum per la direzione dei messaggi
export enum MessageDirection {
  OUT, // Messaggi in uscita (inviati dall'utente corrente)
  IN, // Messaggi in entrata (ricevuti da altri utenti)
}

// Interfaccia per le props del componente DeleteIcon
export interface DeleteIconProps {
  uuid: string;
  deleteAt?: string;
  isVisible: boolean;
  onDelete: (uuid: string) => void;
  direction: MessageDirection;
  isAdmin?: boolean;
}

// Componente interno che gestisce la visualizzazione dell'icona di eliminazione
const DeleteIconComponent = ({
  uuid,
  deleteAt,
  isVisible,
  onDelete,
  direction,
  isAdmin = false,
}: DeleteIconProps) => {
  // Se non è un messaggio in uscita e non è un admin, non mostrare l'icona
  if (!(direction === MessageDirection.OUT || isAdmin)) {
    return null;
  }

  // Se il messaggio è già stato eliminato e l'utente è admin, mostra l'icona di messaggio eliminato
  if (deleteAt && isAdmin) {
    return (
      <Tooltip text={'Messaggio eliminato'} direction="right">
        <XCircleIcon className="w-5 h-5 text-red-500" aria-label="Messaggio eliminato" />
      </Tooltip>
    );
  }

  // Altrimenti mostra l'icona del cestino per eliminare il messaggio
  if (isVisible)
    return (
      <TrashIcon
        className={`w-5 h-5 text-gray-400 cursor-pointer hover:text-red-500 transform transition-opacity duration-300 opacity-0 group-hover:opacity-100 ${
          isVisible ? 'opacity-100' : ''
        }`}
        onClick={(e) => {
          e.stopPropagation(); // Previene la propagazione dell'evento al genitore
          onDelete(uuid);
        }}
        aria-label="Elimina messaggio"
      />
    );

  return null;
};

// Esportiamo il componente memoizzato per evitare ri-renderizzazioni non necessarie
export const DeleteIcon = memo(DeleteIconComponent);
