// std
import { useEffect, useContext, createContext, useCallback, useMemo } from 'react';

// 3p
import { v4 as uuidv4 } from 'uuid';

// app
import {
  IChatMedia,
  IChatMessage,
  IChatMessageAttachment,
  IChatThread,
  IEventOnMessage,
} from 'interfaces';

import { useGetAllThread } from 'api/chat';
import { useMatch } from 'react-router-dom';
import { useSocket } from 'hooks/useSocket';
import { useAuth } from 'hooks/useAuth';

// const waitFor = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

const chatContext = createContext<{
  hasNewMessage: boolean;
  createMessage: (content: string | null) => IChatMessage;
  createAttachment: (media: IChatMedia) => IChatMessageAttachment;
  sendMessage: (
    t: IChatThread,
    m: IChatMessage,
    a?: IChatMessageAttachment
  ) => Promise<any>;
  createChatThread: (username: string) => Promise<IChatThread>;
  blockUser: (username: string) => Promise<void>;
  deleteMessage: (tUuid: string, messageUuid: string) => Promise<void>;
}>({} as any);

// Provider component that wraps your app and makes chat object ...
// ... available to any child component that calls useChat().
export function ChatContextProvider({ children }: { children: JSX.Element }) {
  const chat = useProvideChat();
  return <chatContext.Provider value={chat}>{children}</chatContext.Provider>;
}

// Hook for child components to get the chat object ...
// ... and re-render when it changes.
export const useChat = () => {
  return useContext(chatContext);
};

function useHandleNewMessage() {
  const { getAllThreadQuery, updateLastMessage, updateReadStatus } = useGetAllThread();
  const { data: threads } = getAllThreadQuery;

  const locationMatch = useMatch('/app/chat/:chatId');

  const chatId = useMemo(() => {
    if (!locationMatch) return;

    const { params } = locationMatch;
    const { chatId } = params;

    return chatId;
  }, [locationMatch]);

  const { on, off } = useSocket();

  useEffect(() => {
    const onMessage = async (e: IEventOnMessage) => {
      console.log('onMessage', e);
      const { thread, message } = e;
      // Se non sono io il sender e non mi trovo nella chat ho un nuovo messaggio
      // Non dovrei ricevere mai i miei messaggi
      await updateLastMessage(thread, message);

      const { uuid: tuuid } = thread;
      if (chatId !== tuuid) {
        updateReadStatus(tuuid, true);
      }
    };

    on('chat:onmessage', onMessage);

    return () => {
      off('chat:onmessage', onMessage);
    };
  }, [chatId, off, on, updateLastMessage, updateReadStatus]);

  const hasNewMessage = useMemo(() => {
    if (!threads) return false;
    return threads.pages
      .flatMap(({ data }) => data)
      .filter((message) => message !== undefined)
      .some(({ hasNewMessage }) => !!hasNewMessage);
  }, [threads]);

  return Object.freeze({ hasNewMessage });
}

// Provider hook that creates chat object and handles state
function useProvideChat() {
  const { isConnected, emitWithAck } = useSocket();

  useEffect(() => {
    if (!isConnected) return;
    emitWithAck('chat:register');
  }, [isConnected, emitWithAck]);

  const { hasNewMessage } = useHandleNewMessage();

  /**
   * Creazione di un nuovo thread
   */
  const createChatThread = useCallback(
    async (username: string) => {
      const { status, data } = await emitWithAck('chat:start', { username });

      if (status !== 'ok') {
        throw new Error('chat:start error');
      }

      return data;
    },
    [emitWithAck]
  );

  const { user } = useAuth();

  // * MESSAGES

  /**
   * Invio di un nuovo messaggio
   */
  const createMessage = useCallback(
    (content: string | null) => {
      if (!user) {
        throw new Error('No user');
      }

      if (content && content.length > 1000) {
        throw new Error('Il numero massimo di caratteri per i messaggi è 1000');
      }

      const message = {} as IChatMessage;
      message.sender = user;
      message.content = content ? content.trim() : null;
      message.sendAt = new Date().toISOString();

      const uuid = uuidv4();
      message.uuid = uuid;

      return message;
    },
    [user]
  );

  const createAttachment = useCallback((media: IChatMedia) => {
    const { file, type, preview } = media;

    const attachment = {} as IChatMessageAttachment;
    attachment.name = file.name;
    attachment.mimeType = file.type;
    attachment.size = file.size;
    attachment.type = type;
    attachment.thumbnail = preview;

    return attachment;
  }, []);

  // Send to socket
  const sendMessage = useCallback(
    async (
      thread: IChatThread,
      message: IChatMessage,
      attachment?: IChatMessageAttachment
    ) => {
      const { uuid: tuuid } = thread;

      const response = await emitWithAck('chat:send', {
        tuuid,
        message,
        attachment,
      });

      const { status } = response;

      if (status !== 'ok') {
        const { error } = response;
        throw new Error(error.message);
      }

      const { data } = response;

      return data;
    },
    [emitWithAck]
  );

  const blockUser = useCallback(
    async (username: string) => {
      const response = await emitWithAck('chat:block', { username });

      const { status } = response;

      if (status !== 'ok') {
        const { error } = response;
        throw new Error(error);
      }
    },
    [emitWithAck]
  );

  const deleteMessage = useCallback(
    async (tUuid: string, messageUuid: string) => {
      const response = await emitWithAck('chat:deleteMessage', { tUuid, messageUuid });

      const { status } = response;

      if (status !== 'ok') {
        const { error } = response;
        throw new Error(error);
      }
    },
    [emitWithAck]
  );

  // Return the user object and chat methods
  return Object.freeze({
    createMessage,
    createAttachment,
    sendMessage,
    hasNewMessage,
    createChatThread,
    blockUser,
    deleteMessage,
  });
}
