// std
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// 3p
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

// app
import { EPOCH_PAYMENT_INFO, PaymentGatewayEnum } from 'config/Enum';
import { DEFAULT_GATEWAY } from 'config/Config';

import { IAlbum, IModal, IPayInProductReq, IPayInResponse, IProduct } from 'interfaces';
import { convertFromCent, formatMoney } from 'utils';
import { useAnalytics, useAuth } from 'hooks';
import styles from 'styles';

import { useCreatePayInProduct, useGetAllPayInSource } from 'api/transfer';

import { ContentPaymentSuccess } from './ContentPaymentSuccess';
import { ContentError } from './ContentError';
import { AlertInfo } from '../alert';
import RadioCardsImage from '../radio/RadioCardsImage';
import { ModalLayout } from '../modal';

interface ProductBuyModalProps {
  data?: IProduct | IAlbum;
  open: boolean;
  setOpen:
    | React.Dispatch<React.SetStateAction<IModal<IProduct>>>
    | React.Dispatch<React.SetStateAction<IModal<IAlbum>>>;
  onSuccess?: (data?: IPayInResponse) => void;
  onError?: (error: any) => void;
  onClose: () => void;
}

export function ProductBuyModal(props: ProductBuyModalProps): JSX.Element {
  const { open, onSuccess, onError, onClose, data } = props;

  const [paymentInResponse, setPaymentInResponse] = useState<IPayInResponse>();

  const { user } = useAuth();
  const { createPayInMutation } = useCreatePayInProduct();

  const {
    mutateAsync,
    isLoading: isLoadingCreatePayInUrl,
    isError: isErrorCreatePayInUrl,
  } = createPayInMutation;

  const productPrice = useMemo(() => data?.price, [data]);

  const defaultValues = useMemo(() => {
    return {
      productId: data?.id,
      gateway: DEFAULT_GATEWAY,
    };
  }, [data]);

  const { register, control, handleSubmit, reset, watch, setValue } =
    useForm<IPayInProductReq>({
      defaultValues: defaultValues,
    });

  const gateway = watch('gateway');
  const isPaymentGatewayEpoch = gateway === PaymentGatewayEnum.EPOCH;

  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    if (!productPrice) return;
    setQueryParams({ amount: convertFromCent(productPrice) });
  }, [productPrice, reset]);

  const { getAllPayInSourceQuery } = useGetAllPayInSource(queryParams);
  const { data: dataGetAllPayInSource } = getAllPayInSourceQuery;

  useEffect(() => {
    setValue('gateway', DEFAULT_GATEWAY);
  }, [dataGetAllPayInSource, setValue]);

  const onSubmit: SubmitHandler<IPayInProductReq> = async (data) => {
    try {
      const response = await mutateAsync(data);
      setPaymentInResponse(response);
    } catch (error) {
      if (onError) onError(error);
    }
  };

  const { trackEvent } = useAnalytics();

  const handleOnPaymentSuccess = useCallback(() => {
    if (data) {
      trackEvent('purchase', {
        currency: 'EUR',
        value: convertFromCent(data.price),
        items: [
          {
            item_id: data.id,
            item_name: data.title,
          },
        ],
      });
    }

    if (onSuccess) onSuccess();
  }, [data, onSuccess, trackEvent]);

  const handleOnClickPaymentLink = useCallback(() => {
    if (data) {
      // Save paymenet tracking data in local storage
      localStorage.setItem(
        'payment_data',
        JSON.stringify({
          currency: 'EUR',
          value: convertFromCent(data.price),
          items: [
            {
              item_id: data.id,
              item_name: data.title,
            },
          ],
        })
      );
    }

    if (onSuccess) onSuccess();
  }, [data, onSuccess]);

  // Set Default data and clean
  useEffect(() => {
    if (!open) {
      setPaymentInResponse(undefined);
    }
    reset(defaultValues);
  }, [defaultValues, open, reset]);

  const renderData = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.modal.closeContainer}>
        <button type="button" className={styles.modal.closeButton} onClick={onClose}>
          <span className="sr-only">Close</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 sm:text-left w-full">
          <Dialog.Title as="h3" className={styles.modal.title}>
            Totale: {productPrice ? formatMoney(productPrice) : 'Gratuito'}
          </Dialog.Title>

          <div className="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-3">
            <div className="sm:col-span-3">
              {user ? (
                <>
                  <label className={styles.input.label}>Username</label>
                  <div className={styles.input.containerRelative}>
                    <input
                      disabled
                      type="text"
                      className={styles.input.base + ' ' + styles.input.disabled}
                      defaultValue={user.username}
                    />
                  </div>
                </>
              ) : (
                <>
                  <label htmlFor="email" className={styles.input.label}>
                    Indirizzo e-mail
                  </label>
                  <div className={styles.input.containerRelative}>
                    <input
                      id="email"
                      type="email"
                      className={styles.input.base}
                      {...register('email', { required: true })}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="sm:col-span-3">
              <label className={styles.input.label}>Prodotto</label>
              <div className={styles.input.containerRelative}>
                <input
                  disabled
                  type="text"
                  className={styles.input.base + ' ' + styles.input.disabled}
                  defaultValue={data?.title}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="gateway" className={styles.input.label + ' mb-2'}>
                Scegli la modalità di pagamento
              </label>
              <Controller
                name="gateway"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <RadioCardsImage
                    onChange={onChange}
                    value={value}
                    options={dataGetAllPayInSource}
                  />
                )}
              />
            </div>
            {isPaymentGatewayEpoch && (
              <div className="sm:col-span-3">
                <AlertInfo message={EPOCH_PAYMENT_INFO} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.modal.footer}>
        <button type="submit" className={styles.modal.button1}>
          Acquista
        </button>
        <button type="button" className={styles.modal.button2} onClick={onClose}>
          Annulla
        </button>
      </div>
    </form>
  );

  return (
    <ModalLayout open={open} onClose={onClose} isLoading={isLoadingCreatePayInUrl}>
      {paymentInResponse ? (
        <ContentPaymentSuccess
          payment={paymentInResponse}
          onSuccess={handleOnPaymentSuccess}
          onClickLink={handleOnClickPaymentLink}
        />
      ) : data ? (
        renderData()
      ) : isErrorCreatePayInUrl ? (
        <ContentError />
      ) : (
        <div />
      )}
    </ModalLayout>
  );
}
