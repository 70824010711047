// hooks/useDevice.ts
import { useState, useEffect } from 'react';

interface DeviceInfo {
  isMobile: boolean;
  isDesktop: boolean;
}

export const useDevice = (): DeviceInfo => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    isMobile: false,
    isDesktop: true
  });

  useEffect(() => {
    const checkDevice = () => {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      setDeviceInfo({
        isMobile: isMobileDevice,
        isDesktop: !isMobileDevice
      });
    };

    // Controlla al montaggio del componente
    checkDevice();

    // Aggiorna in caso di ridimensionamento (es. rotazione del dispositivo)
    window.addEventListener('resize', checkDevice);

    return () => {
      window.removeEventListener('resize', checkDevice);
    };
  }, []);

  return deviceInfo;
};