import { useParams } from 'react-router-dom';

// 3p
import { useAdminGetAllMessage, useAdminGetThread } from 'api/admin/chat';

// app
import { ChatAreaContent, ChatAreaHeader } from 'components/common/chat';
import { Loader } from 'components/common';
import { useCallback, useMemo } from 'react';
import { useChat } from 'hooks';
import { useGetAllThreadMessage } from 'api/chat';

export const AdminConversation = () => {
  const { chatId } = useParams();

  // Load thread
  const { adminGetThreadQuery } = useAdminGetThread(chatId);

  const {
    data: threadQueryResult,
    isLoading: threadQueryIsLoading,
    // isError: threadQueryIsError,
    // error: threadQueryError,
  } = adminGetThreadQuery;

  const { adminGetAllThreadMessageQuery } = useAdminGetAllMessage(chatId);

  const { deleteMessage: deleteMessageSocket } = useChat();

  const { deleteMessage } = useGetAllThreadMessage(chatId!);

  const {
    data: messageQueryResult,
    // isError: messageQueryIsError,
    // error: messageQueryError,
    // isLoading: messageQueryIsLoading,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
  } = adminGetAllThreadMessageQuery;

  const messages = useMemo(() => {
    if (!messageQueryResult) return [];
    return messageQueryResult.pages.flatMap((page) => page.data);
  }, [messageQueryResult]);

  const fetchPreviousMessages = useCallback(async () => {
    if (hasPreviousPage) {
      fetchPreviousPage();
    }
  }, [fetchPreviousPage, hasPreviousPage]);

  const handleDeleteMessage = useCallback(
    async (messageId: string) => {
      if (window.confirm('Sei sicuro di voler eliminare questo messaggio?')) {
        try {
          if (threadQueryResult && threadQueryResult.uuid) {
            // Prima invia la richiesta al server attraverso la socket
            await deleteMessageSocket(threadQueryResult.uuid, messageId);

            // Poi aggiorna l'interfaccia utente rimuovendo il messaggio dalla cache
            deleteMessage(messageId);
          }
        } catch (error) {
          console.error("Errore durante l'eliminazione del messaggio:", error);
          alert('Non è stato possibile eliminare il messaggio');
        }
      }
    },
    [threadQueryResult, deleteMessageSocket, deleteMessage]
  );

  if (threadQueryIsLoading || !threadQueryResult) {
    return <Loader />;
  }

  return (
    <>
      <ChatAreaHeader thread={threadQueryResult} />
      <ChatAreaContent
        messages={messages}
        isFetching={isFetchingPreviousPage}
        fetch={fetchPreviousMessages}
        showSenderUsername
        onDeleteMessage={handleDeleteMessage}
      />
    </>
  );
};
