// std

// 3p
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';

// app
import { IChatThread } from 'interfaces';
import { ENV_COMMUNICATION } from 'config';

import APIClient from '../ApiClient';
import { useCallback, useMemo } from 'react';

const getThread = async ({ queryKey }: any) => {
  const threadId = queryKey[1];

  const url = ENV_COMMUNICATION.GET_THREAD(threadId);

  const { data } = await APIClient.get<IChatThread>(url);

  return data;
};

export function useGetThread(threadId?: string, pagingQuery?: any) {
  const queryKey = useMemo(
    () => ['getThread', threadId, pagingQuery],
    [threadId, pagingQuery]
  );

  const getThreadQuery = useQuery<IChatThread, AxiosError<string, any>>({
    queryKey: queryKey,
    queryFn: getThread,
    enabled: !!threadId,
    refetchOnWindowFocus: true,
  });

  const queryClient = useQueryClient();

  const setThreadBlock = useCallback(
    (threadId: string, isBlocked: boolean) => {
      queryClient.setQueryData(queryKey, (oldData: any) => ({
        ...oldData,
        isBlocked,
      }));
      // queryClient.invalidateQueries({ queryKey });
    },
    [queryClient, queryKey]
  );

  return { getThreadQuery, setThreadBlock };
}
