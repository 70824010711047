// std
import { useEffect, useContext, createContext, useCallback } from 'react';

// 3p
import { useNavigate } from 'react-router-dom';
import Bowser from 'bowser';

// app
import { useMeetingModal } from 'components/common/chat/video';
import { useSocket } from 'hooks/useSocket';

function checkBrowserSupport() {
  const supportedBrowsers = [
    { name: 'Chrome', minVersion: 78 },
    { name: 'Microsoft Edge', minVersion: 83 },
    { name: 'Firefox', minVersion: 78 },
    { name: 'Opera', minVersion: 78 },
    { name: 'Safari', minVersion: 13.1 },
    { name: 'Samsung Internet for Android', minVersion: 4 },
  ];

  const browser = Bowser.getParser(window.navigator.userAgent);
  const { name: browserName, version: browserVersion } = browser.getBrowser();

  const browserFound = supportedBrowsers.find(({ name }) => name === browserName);

  if (!browserFound) {
    return false;
  }

  const { minVersion: broswerMinVersion } = browserFound;

  if (!browserVersion || parseFloat(browserVersion) < broswerMinVersion) {
    return false;
  }

  return true;
}

type IMeetingContext = {
  startCall: (username: string) => void;
  endCall: () => Promise<void>;
};

type IMeetingEventOnAnswer = {
  accept: boolean;
  meetingId: string;
};

class UserNotAvailable extends Error {
  constructor(message: string) {
    super();
    this.message = message;
    this.name = 'UserNotAvailable';
  }
}

// const waitFor = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

const meetingContext = createContext<IMeetingContext>({} as IMeetingContext);

// Provider component that wraps your app and makes meeting object ...
// ... available to any child component that calls useMeeting().
export function MeetingContextProvider({ children }: { children: JSX.Element }) {
  const meeting = useProvideMeeting();
  return <meetingContext.Provider value={meeting}>{children}</meetingContext.Provider>;
}

// Hook for child components to get the meeting object ...
// ... and re-render when it changes.
export const useMeeting = () => {
  return useContext(meetingContext);
};

// Provider hook that creates meeting object and handles state
function useProvideMeeting() {
  const navigate = useNavigate();

  const { isConnected, emitWithAck, on, off } = useSocket();

  useEffect(() => {
    if (!isConnected) return;
    emitWithAck('meeting:register');
  }, [isConnected, emitWithAck]);

  const {
    showIncomingCall,
    closeIncomingCall,
    showOutcomingCall,
    closeOutcomingCall,
    showErrorCall,
  } = useMeetingModal();

  // * Listen to the incoming call
  useEffect(() => {
    // If the caller has cancelled the call
    function handleCancelIncomingCall() {
      closeIncomingCall();
    }

    function handleStartIncomingCall(e: any) {
      console.log('handleStartIncomingCall', e);
      const { displayName, meetingId } = e;

      if (!checkBrowserSupport()) {
        showErrorCall(
          'Attenzione, il tuo broswer non è supportato. Per ricevere videochiamate utilizza uno tra Google Chrome, Microsoft Edge, Safari, Opera o Firefox'
        );

        emitWithAck('meeting:answer', {
          accept: false,
          username: displayName,
        });

        return;
      }

      const accept = () => {
        emitWithAck('meeting:answer', {
          accept: true,
          username: displayName,
        })
          .then((e) => {
            const { data, status } = e;
            if (status === 'ok') {
              const { authToken, threadId } = data;
              navigate(`/app/meeting/${meetingId}`, { state: { authToken, threadId } });
            }
          })
          .finally(() => closeIncomingCall());
      };

      const reject = () => {
        emitWithAck('meeting:answer', {
          accept: false,
          username: displayName,
        }).finally(() => closeIncomingCall());
      };

      //* I'm getting a call, listening for the cancel
      on('meeting:oncancel', handleCancelIncomingCall);

      showIncomingCall(accept, reject, displayName);
    }

    on('meeting:onincoming', handleStartIncomingCall);

    return () => {
      off('meeting:onincoming', handleStartIncomingCall);
      off('meeting:oncancel', handleCancelIncomingCall);
    };
  }, [
    closeIncomingCall,
    emitWithAck,
    navigate,
    off,
    on,
    showErrorCall,
    showIncomingCall,
  ]);

  //* Start the call
  const startCall = useCallback(
    async (username: string) => {
      if (!checkBrowserSupport()) {
        showErrorCall(
          'Attenzione, il tuo broswer non è supportato. Per effettuare videochiamate utilizza uno tra Google Chrome, Microsoft Edge, Safari, Opera o Firefox'
        );
        return;
      }

      function onAnswer(meetingId: string, authToken: string, threadId: string) {
        return function ({ accept }: IMeetingEventOnAnswer) {
          if (accept) {
            navigate(`/app/meeting/${meetingId}`, { state: { authToken, threadId } });
          } else {
            showErrorCall('La chiamata è stata rifiutata');
          }

          closeOutcomingCall();
        };
      }

      // I wish to cancel the call
      function cancelCall() {
        emitWithAck('meeting:cancel', { username }).finally(() => closeOutcomingCall());
      }

      try {
        const { status, data } = await emitWithAck('meeting:start', { username });

        if (status === 'ok') {
          const { meetingId, authToken, threadId } = data;

          //* I am making a call. listen to answer
          const cb = onAnswer(meetingId, authToken, threadId);

          showOutcomingCall(cb, cancelCall);
        } else {
          throw new UserNotAvailable(
            'Impossibile avviare la chiamata: Utente non disponibile'
          );
        }
      } catch (e) {
        console.error(e);

        if (e instanceof UserNotAvailable) {
          alert(e.message);
        } else {
          showErrorCall(
            'Impossibile avviare la chiamata a causa di un errore connessione'
          );
        }
      }
    },
    [closeOutcomingCall, emitWithAck, navigate, showErrorCall, showOutcomingCall]
  );

  const endCall = useCallback(async () => {}, []);

  // Return the user object and meeting methods
  return Object.freeze({
    startCall,
    endCall,
  });
}
