import React from 'react';

// 3p
import { QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';

// app
import { AppRouter } from 'views/app';
import { WebRouter } from 'views/web';
import { AdminRouter } from 'views/admin';

import {
  ToastContextProvider,
  AuthContextProvider,
  ChatContextProvider,
  MeetingContextProvider,
  SocketContextProvider,
  NotificationContextProvider,
  AnalyticsContextProvider,
} from 'hooks';
import { MeetingModalContextProvider } from 'components/common/chat/video';

import ScrollToTop from 'components/common/ScrollToTop';
import AdultConsentModal from 'components/common/AdultConsentModal';
import CookieConsentBanner from 'components/common/CookieConsentBanner';

import { PrivateRoute } from 'utils';

import * as CONFIG from './config/Config';

import { MainLayout } from 'layouts/web';

import './App.css';

import { QUERY_CLIENT } from 'libs';
import { clarity } from 'react-microsoft-clarity';

if (!CONFIG.IS_DEV) {
  clarity.init('pujz3sr58d');
}

function App() {
  return (
    <>
      <ScrollToTop />
      <AnalyticsContextProvider>
        <QueryClientProvider client={QUERY_CLIENT}>
          <NotificationContextProvider>
            <AuthContextProvider>
              <ToastContextProvider>
                <SocketContextProvider>
                  <ChatContextProvider>
                    <MeetingModalContextProvider>
                      <MeetingContextProvider>
                        <Routes>
                          <Route element={<PrivateRoute />}>
                            <Route path="/admin/*" element={<AdminRouter />} />
                          </Route>
                          <Route element={<PrivateRoute />}>
                            <Route path="/app/*" element={<AppRouter />} />
                          </Route>
                          <Route element={<MainLayout />}>
                            <Route path="/*" element={<WebRouter />} />
                          </Route>
                        </Routes>
                      </MeetingContextProvider>
                    </MeetingModalContextProvider>
                  </ChatContextProvider>
                </SocketContextProvider>
              </ToastContextProvider>
            </AuthContextProvider>
          </NotificationContextProvider>
        </QueryClientProvider>
        <AdultConsentModal />
        <CookieConsentBanner />
      </AnalyticsContextProvider>
    </>
  );
}

export default App;
