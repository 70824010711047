// std

// 3p
import { AxiosError } from 'axios';
import { useInfiniteQuery, useQueryClient } from 'react-query';

// app
import { IChatMessage, IResponseWithCursor } from 'interfaces';
import { ENV_COMMUNICATION, FileStatusEnum } from 'config';

import APIClient from '../ApiClient';
import { useCallback, useMemo } from 'react';

interface IChatMessageCache {
  pages: IResponseWithCursor<IChatMessage>[];
}

const getAllThreadMessage = async ({ queryKey, pageParam }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [url, pagingQuery] = queryKey;

  const { data } = await APIClient.get<IResponseWithCursor<IChatMessage>>(url, {
    params: {
      ...pagingQuery,
      ...(pageParam ? pageParam : {}),
    },
  });

  return data;
};

export function useGetAllThreadMessage(chatId: string, pagingQuery?: any) {
  const queryKey = useMemo(
    () => [ENV_COMMUNICATION.GET_ALL_THREAD_MESSAGE(chatId), pagingQuery],
    [chatId, pagingQuery]
  );

  const getAllThreadMessageQuery = useInfiniteQuery<
    IResponseWithCursor<IChatMessage>,
    AxiosError<string, any>
  >({
    queryKey: queryKey,
    queryFn: getAllThreadMessage,
    enabled: !!chatId,
    getPreviousPageParam: (lastPage) => {
      const { cursor } = lastPage;

      if (!cursor) {
        return null;
      }

      const { beforeCursor } = cursor;

      if (!beforeCursor) {
        return null;
      }

      return { beforeCursor };
    },
    retry: (failureCount, error) => {
      if (error.response?.status === 404) {
        console.error('Chat non disponibile');
        return false;
      }
      return true;
    },
  });

  const queryClient = useQueryClient();

  const updateMessage = useCallback(
    (message: IChatMessage) => {
      queryClient.cancelQueries(queryKey).then(() => {
        const messages = queryClient.getQueryData<IChatMessageCache>(queryKey);

        if (!messages) {
          queryClient.setQueryData(queryKey, { pages: [message] });
          return;
        }

        const cacheClone = structuredClone(messages);
        const lastPage = cacheClone.pages[cacheClone.pages.length - 1];

        // Trova l'indice del messaggio esistente (se presente)
        const existingMessageIndex = lastPage.data.findIndex(
          (msg: IChatMessage) => msg.uuid === message.uuid
        );

        if (existingMessageIndex !== -1) {
          // Modifica il messaggio esistente
          lastPage.data[existingMessageIndex] = message;
        } else {
          // Aggiungi il messaggio se non esiste
          lastPage.data.push(message);
        }

        queryClient.setQueryData(queryKey, cacheClone);
      });

      // queryClient.invalidateQueries({ queryKey: queryKey });
    },
    [queryClient, queryKey]
  );

  const deleteMessage = useCallback(
    (messageId: string) => {
      queryClient.cancelQueries(queryKey).then(() => {
        const messages = queryClient.getQueryData<IChatMessageCache>(queryKey);

        if (!messages) {
          return;
        }

        const cacheClone = structuredClone(messages);
        const lastPage = cacheClone.pages[cacheClone.pages.length - 1];

        // Filtra il messaggio eliminato
        lastPage.data = lastPage.data.filter(
          (msg: IChatMessage) => msg.uuid !== messageId
        );

        queryClient.setQueryData(queryKey, cacheClone);
      });
    },
    [queryClient, queryKey]
  );

  const updateMessageVideoStatus = useCallback(
    (videoId: string, status: FileStatusEnum, progress?: number) => {
      queryClient.cancelQueries(queryKey).then(() => {
        const cache = queryClient.getQueryData<IChatMessageCache>(queryKey);

        if (!cache) return;

        const pages = cache.pages.map((page: any) => {
          return {
            ...page,
            data: page.data.map((message: any) => {
              const { video } = message;
              if (video && video.id === videoId) {
                return {
                  ...message,
                  video: {
                    ...video,
                    status: status,
                    progress,
                  },
                };
              }
              return message;
            }),
          };
        });

        // Impostiamo il nuovo cache
        queryClient.setQueryData(queryKey, { ...cache, pages });
      });
    },
    [queryClient, queryKey]
  );

  return {
    queryKey,
    getAllThreadMessageQuery,
    updateMessage,
    updateMessageVideoStatus,
    deleteMessage,
  };
}
