import React, { useState, useRef, useEffect, useCallback } from 'react';
import { StopIcon, TrashIcon } from '@heroicons/react/outline';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player';
import { LiveAudioVisualizer } from 'react-audio-visualize';

type Props = {
  onSuccess?: (fileName: string, file: File) => void;
  onCancel: () => void;
};

const SUPPORTED_MIME_TYPES = [
  'audio/mp4', // Chrome, Safari
  'audio/webm;codecs=opus', // Firefox, Chrome
  'audio/webm', // Fallback generico per WebM
];

const getSupportedMimeType = () => {
  return (
    SUPPORTED_MIME_TYPES.find((mimeType) => MediaRecorder.isTypeSupported(mimeType)) ||
    null
  );
};

function useTimer() {
  const timerInterval = useRef<NodeJS.Timer | null>(null);
  const [recordingTime, setRecordingTime] = useState<number>(0);

  const startTimer = useCallback(() => {
    timerInterval.current = setInterval(() => {
      setRecordingTime((time: number) => time + 1);
    }, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
      timerInterval.current = null;
    }
    setRecordingTime(0);
  }, []);

  return Object.freeze({ recordingTime, startTimer, stopTimer });
}

export const ChatAttachmentAudio: React.FC<Props> = (props) => {
  const { onCancel, onSuccess } = props;

  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null);
  const [permissionError, setPermissionError] = useState<string | null>(null);
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [showVisualizer, setShowVisualizer] = useState(false);

  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);

  const getMediaStream = useCallback(async () => {
    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('Il tuo browser non supporta la registrazione audio');
      }

      let stream = await window.navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      setPermissionError(null);
      setIsPermissionDenied(false);
      return stream;
    } catch (err: any) {
      if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
        setIsPermissionDenied(true);
        setPermissionError(
          "Permesso per il microfono negato. Per favore concedi l'accesso al microfono nelle impostazioni del browser."
        );
      } else if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
        setPermissionError(
          'Nessun dispositivo audio trovato. Collega un microfono e riprova.'
        );
      } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
        setPermissionError("Il microfono è già in uso da un'altra applicazione.");
      } else {
        setPermissionError(
          "Si è verificato un errore durante l'accesso al microfono: " + err.message
        );
      }
      console.error('Errore accesso microfono:', err);
      throw err;
    }
  }, []);

  const clearMediaStream = useCallback(() => {
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
      mediaStream.current = null;
    }
  }, []);

  const { startTimer, stopTimer, recordingTime } = useTimer();

  const handleDataAvailable = useCallback((e: BlobEvent) => {
    mediaChunks.current.push(e.data);
  }, []);

  const handleStop = useCallback(() => {
    if (mediaChunks.current.length) {
      let [sampleChunk] = mediaChunks.current;
      let blobPropertyBag = { type: sampleChunk.type };
      setRecordingBlob(new Blob(mediaChunks.current, blobPropertyBag));
    }
  }, []);

  const handleError = useCallback((e: any) => {
    console.error('Recording Error', e);
  }, []);

  const handleDeleteRecording = useCallback(() => {
    setRecordingBlob(null);
    clearMediaStream();
    onCancel();
  }, [onCancel, clearMediaStream]);

  const handleStartRecording = useCallback(async () => {
    try {
      if (!mediaStream.current) {
        await getMediaStream();
      }

      mediaChunks.current = [];

      if (!mediaStream.current) {
        return;
      }

      const mimeType = getSupportedMimeType();
      if (!mimeType) {
        throw new Error(
          'Nessun formato di registrazione audio supportato dal tuo browser'
        );
      }

      mediaRecorder.current = new MediaRecorder(mediaStream.current, {
        mimeType: mimeType,
      });

      mediaRecorder.current.addEventListener('dataavailable', handleDataAvailable);
      mediaRecorder.current.addEventListener('stop', handleStop);
      mediaRecorder.current.addEventListener('error', handleError);

      mediaRecorder.current.start();
      setIsRecording(true);
      setShowVisualizer(true);
      startTimer();
    } catch (error) {
      handleError({ error });
      throw error;
    }
  }, [getMediaStream, handleDataAvailable, handleError, handleStop, startTimer]);

  const handleStopRecording = useCallback(() => {
    if (!mediaRecorder.current) return;

    setShowVisualizer(false);

    setTimeout(() => {
      stopTimer();
      setIsRecording(false);

      if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
        const handleStopOnce = () => {
          if (mediaChunks.current.length) {
            let [sampleChunk] = mediaChunks.current;
            let blobPropertyBag = { type: sampleChunk.type };
            setRecordingBlob(new Blob(mediaChunks.current, blobPropertyBag));
          }

          if (mediaRecorder.current) {
            mediaRecorder.current.removeEventListener(
              'dataavailable',
              handleDataAvailable
            );
            mediaRecorder.current.removeEventListener('stop', handleStopOnce);
            mediaRecorder.current.removeEventListener('error', handleError);
            mediaRecorder.current = null;
          }

          clearMediaStream();
        };

        mediaRecorder.current.removeEventListener('stop', handleStop);
        mediaRecorder.current.addEventListener('stop', handleStopOnce, { once: true });

        mediaRecorder.current.stop();
      } else {
        if (mediaRecorder.current) {
          mediaRecorder.current.removeEventListener('dataavailable', handleDataAvailable);
          mediaRecorder.current.removeEventListener('stop', handleStop);
          mediaRecorder.current.removeEventListener('error', handleError);
          mediaRecorder.current = null;
        }

        clearMediaStream();
      }
    }, 100);
  }, [stopTimer, handleDataAvailable, handleStop, handleError, clearMediaStream]);

  const handleSendAudio = useCallback(async () => {
    if (!recordingBlob) {
      console.error('Nessun blob di registrazione disponibile');
      return;
    }

    try {
      console.log("Preparazione file audio per l'invio...");

      const mimeType = getSupportedMimeType() || 'audio/webm';
      const fileExtension = mimeType.includes('webm') ? '.webm' : '.mp4';
      const fileName = uuidv4();
      const audioFile = new File([recordingBlob], fileName + fileExtension, {
        type: mimeType,
      });

      console.log('File audio creato:', {
        name: audioFile.name,
        type: audioFile.type,
        size: audioFile.size,
      });

      if (onSuccess) {
        console.log('Chiamata alla funzione onSuccess...');
        await onSuccess(fileName, audioFile);
        console.log('File audio inviato con successo');
      } else {
        console.error('La funzione onSuccess non è definita');
      }
    } catch (error) {
      console.error("Errore durante l'invio del file audio:", error);
      alert(
        "Si è verificato un errore durante l'invio del file audio. Per favore riprova."
      );
    }
  }, [recordingBlob, onSuccess, getSupportedMimeType]);

  useEffect(() => {
    (async function () {
      try {
        await handleStartRecording();
      } catch (e) {
        console.error(e);
        alert(e);
        handleDeleteRecording();
        onCancel();
      }
    })();

    return () => {
      setShowVisualizer(false);

      setTimeout(() => {
        if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
          try {
            mediaRecorder.current.stop();
          } catch (e) {
            console.error('Errore durante lo stop del mediaRecorder:', e);
          }
        }

        if (mediaRecorder.current) {
          mediaRecorder.current.removeEventListener('dataavailable', handleDataAvailable);
          mediaRecorder.current.removeEventListener('stop', handleStop);
          mediaRecorder.current.removeEventListener('error', handleError);
        }

        clearMediaStream();
      }, 100);
    };
  }, [
    handleStartRecording,
    handleDeleteRecording,
    onCancel,
    clearMediaStream,
    handleDataAvailable,
    handleStop,
    handleError,
  ]);

  return (
    <div
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '25px 20px',
      }}
      className="bg-gray-200 flex flex-col"
    >
      {permissionError && (
        <div className="text-red-500 text-center mb-4 px-4">
          {permissionError}
          {isPermissionDenied && (
            <button
              onClick={handleDeleteRecording}
              className="mt-2 text-sm text-gray-600 underline"
            >
              Chiudi registrazione
            </button>
          )}
        </div>
      )}

      {!permissionError && (
        <div className="flex w-full justify-between items-center">
          {recordingBlob ? (
            <>
              <button type="button" onClick={handleDeleteRecording}>
                <TrashIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
              </button>
              <div className="w-full h-12 px-8">
                <ReactPlayer
                  url={URL.createObjectURL(recordingBlob)}
                  controls
                  config={{
                    file: {
                      forceAudio: true,
                      attributes: { controlsList: 'nodownload' },
                    },
                  }}
                  width="100%"
                  height="100%"
                />
              </div>
              <button
                type="button"
                className="focus:outline-none text-pink-600 hover:text-pink-700"
                onClick={handleSendAudio}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                  />
                </svg>
              </button>
            </>
          ) : (
            <>
              <div className="text-red-500 font-bold">
                {recordingTime && (
                  <>
                    {Math.floor(recordingTime / 60)}:
                    {String(recordingTime % 60).padStart(2, '0')}
                  </>
                )}
              </div>
              {isRecording &&
                mediaRecorder.current &&
                mediaRecorder.current.state === 'recording' &&
                showVisualizer && (
                  <LiveAudioVisualizer
                    mediaRecorder={mediaRecorder.current}
                    barWidth={2}
                    gap={2}
                    width={140}
                    height={30}
                    fftSize={512}
                    maxDecibels={-10}
                    minDecibels={-80}
                    smoothingTimeConstant={0.4}
                    barColor="#ef4444"
                  />
                )}
              <StopIcon
                className="cursor-pointer h-10 w-10 text-red-500"
                onClick={handleStopRecording}
                aria-hidden="true"
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};
