import { useRef, useState } from 'react';

interface VideoPlayerProps {
  src: string;
  poster?: string;
  className?: string;
  title?: string;
  description?: string;
}

export function VideoPlayerCard({ src, poster, className = '', title, description }: VideoPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoClick = (e: React.MouseEvent<HTMLVideoElement>) => {
    // Prevent click when interacting with controls
    const target = e.target as HTMLVideoElement;
    const rect = target.getBoundingClientRect();
    const clickY = e.clientY - rect.top;
    
    // If click is not in the bottom area where controls usually are
    if (clickY < rect.height - 40) {
      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    }
  };

  return (
    <div className={`w-full bg-white sm:shadow-md sm:rounded-xl space-y-4 p-2 ${className}`}>
      {title && (
        <h2 className="text-3xl text-pink-400 font-extrabold text-center mb-4">
          {title}
        </h2>
      )}
      {description && (
        <p className="text-center text-pink-600 mb-4">
          {description}
        </p>
      )}
      <div className="relative rounded-lg overflow-hidden">
        <div className="relative aspect-video">
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            poster={poster}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onEnded={() => setIsPlaying(false)}
            onClick={handleVideoClick}
            controls={true}
          >
            <source src={src} type="video/mp4" />
            Il tuo browser non supporta il tag video.
          </video>
          {!isPlaying && (
            <button
              onClick={() => {
                if (videoRef.current) {
                  videoRef.current.play();
                }
              }}
              className="absolute inset-0 w-full h-full flex items-center justify-center bg-black bg-opacity-30 hover:bg-opacity-40 transition-opacity group"
            >
              <div className="w-20 h-20 rounded-full bg-white bg-opacity-90 flex items-center justify-center group-hover:scale-110 transition-transform">
                <svg
                  className="w-12 h-12 text-gray-900"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
