import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import * as CONFIG from '../../config/Config';
import { useAnalytics } from 'hooks/useAnalytics';

/* ButtonComponent={({ style, onClick, children }) => (
  <div className="flex flex-wrap items-center justify-end mt-4 gap-2">
    <button
      className="btn bg-gray-500 text-white text-xs px-4 py-2 rounded"
      onClick={() => setAnalyticsConsent('anonymous')}
    >
      Solo Essenziali
    </button>
    <button
      className="btn bg-red-500 text-white text-xs px-4 py-2 rounded"
      onClick={() => setAnalyticsConsent('none')}
    >
      Rifiuta
    </button>
    <button
      className="btn bg-pink-600 text-white text-xs px-4 py-2 rounded"
      onClick={() => setAnalyticsConsent('full')}
    >
      Accetta
    </button>
  </div>
)} */

const CookieConsentBanner: React.FC = () => {
  const [showDetails, setShowDetails] = useState(false);

  const { setAnalyticsConsent } = useAnalytics();

  return (
    <CookieConsent
      overlay={true}
      acceptOnScroll={true}
      acceptOnOverlayClick={true}
      location="bottom"
      buttonText="Accetto"
      cookieName={CONFIG.LOCAL_STORAGE_TRACKING_CONSENT_KEY}
      style={{
        background: '#2B373B',
        zIndex: 1000,
        maxWidth: '100%',
        padding: '15px 20px',
      }}
      contentStyle={{
        flex: '1 0 300px',
        margin: '15px 0',
      }}
      buttonStyle={{
        color: '#ffffff',
        backgroundColor: '#ec4899',
        fontSize: '13px',
        borderRadius: '4px',
        padding: '8px 15px',
        marginLeft: '10px',
      }}
      expires={150}
      onAccept={() => {
        setAnalyticsConsent('full');
      }}
      enableDeclineButton
      declineButtonText="Rifiuto"
      declineButtonStyle={{
        color: '#ffffff',
        backgroundColor: '#666',
        fontSize: '13px',
        borderRadius: '4px',
        padding: '8px 15px',
        marginLeft: '10px',
      }}
      onDecline={() => {
        setAnalyticsConsent('none');
      }}
    >
      <div className="text-sm">
        <div className="mb-2">
          <p className="font-semibold text-base">Informativa sui Cookie</p>
          <p className="mt-2">
            Questo sito utilizza cookie per migliorare l'esperienza utente e analizzare il
            traffico.
          </p>
        </div>

        <div className="mt-3">
          <button
            className="text-white underline text-xs"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'Nascondi dettagli' : 'Mostra dettagli'}
          </button>

          {showDetails && (
            <div className="mt-3 text-xs">
              <div className="mb-2">
                <p className="font-semibold">Accetta:</p>
                <p>
                  Consenti l'uso completo di cookies per analisi, personalizzazione e
                  marketing. I tuoi dati di navigazione saranno utilizzati per migliorare
                  la tua esperienza e offrire contenuti personalizzati.
                </p>
              </div>

              {/* <div className="mb-2">
                <p className="font-semibold">Solo Essenziali:</p>
                <p>
                  Raccogliamo dati in modo anonimizzato (senza cookie persistenti, con IP
                  mascherato) solo per comprendere il funzionamento del sito. Non
                  raccogliamo informazioni che possano identificarti personalmente.
                </p>
              </div> */}

              <div className="mb-2">
                <p className="font-semibold">Rifiuta:</p>
                <p>
                  Non verrà installato alcun cookie non tecnico e non sarà effettuato
                  alcun tipo di tracciamento.
                </p>
              </div>

              <p className="mt-2">
                Per maggiori informazioni, consulta la nostra{' '}
                <a href="/legal/privacy" className="underline">
                  Informativa sulla Privacy
                </a>
                .
              </p>
            </div>
          )}
        </div>
      </div>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
