import styles from './Tooltip.module.css';

interface TooltipProps {
  text: string;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  children: React.ReactNode;
}

export const Tooltip = ({ text, direction = 'top', children }: TooltipProps) => {
  return (
    <div className={`${styles.tooltip} ${styles[`tooltip-${direction}`]}`}>
      {children}
      <span className={`${styles.tooltiptext} ${styles[`tooltiptext-${direction}`]}`}>
        {text}
      </span>
    </div>
  );
};
